<script>
import Layout from "../../layouts/main";
import { VueEditor, Quill  } from "vue2-editor";
import {
  CREATE_PAYMENT_PAGE_RECORD_IN_STORE,
  UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE,
  UPLOAD_PAGE_IMAGE_IN_STORE
} from '@/state/mutations.type';

import {
  FETCH_PAYMENT_PAGE_DETAILS,
  CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY,
  //UPDATE_MERCHANT_ENTITY_DETAILS,
} from "@/state/actions.type";

import * as cloneDeep from "lodash.clonedeep";
import { required, email } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { parsePhoneNumber } from 'libphonenumber-js';
import draggable from 'vuedraggable';
import CloseModalBtn from '../../../components/close-modal-btn.vue';
import Common from '../../../helpers/Common';
import PaymentMethodModal from '../../../components/payment-method-modal.vue';

let fonts = Quill.import('formats/font')
// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana', 'Sans Serif', 'Serif', 'Monospace'];
const fontNames = fontList.map(font => font.toLowerCase().replace(/\s/g, "-"));

fonts.whitelist = fontNames;
Quill.register(fonts, true);

let fontStyles = "";
fontList.forEach(function(font) {
    let fontName = font.toLowerCase().replace(/\s/g, "-");
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  components: {
    Layout,
    VueEditor,
    VuePhoneNumberInput,
    draggable,
    CloseModalBtn,
    PaymentMethodModal
  },
  data() {
    return {
      processing: false,
      // phoneCountry: "",
      previewPageDetails: null,
      pageDetails: null,
      phoneNumberVal: null,
      userName: "",
      isCustomUrl: false,
      isEdit: false,
      isPreview: false,
      showPreview: false,
      isValidCustomUrl: false,
      toastOpen: false,
      showType: false,
      showUnitAvailablity: false,
      customPageRef: "",
      isTermsAndConditions: false,
      broker_notification_email: "",
      logo: [],
      duplicateCustomFields: [],
      isInProcess: false,
      form: {
        "title": "",
        "description": "<p>Enter Page Description</p>",
        "success_url": `${process.env.VUE_APP_CHECKOUT_BASE_URL}/success.html`,
        "failure_url": `${process.env.VUE_APP_CHECKOUT_BASE_URL}/failure.html`,
        "pending_url": `${process.env.VUE_APP_CHECKOUT_BASE_URL}/pending.html`,
        "currency": "",
        "amount": 0,
        "country_code": "",
        "merchant_details": {
          "logo": "",
          "brand_primary_color": "",
          "brand_secondary_color": "",
          "email_address": "",
          "phone_number": ""
        },
        "notify_by_email": false,
        "link_description": "",
        "terms_and_conditions": "",
        "custom_fields": {
          "fields": [
            {
              type: "name",
              is_mandatory: true,
              title: "Name",
              tooltip: "",
              description: "Enter your name",
              sort_order: 1,
              is_active: true,
              drop_down_options: {
                options: []
              },
              option_text: ""
            },
            {
              type: "email",
              is_mandatory: true,
              title: "Email",
              tooltip: "",
              description: "Enter your email",
              sort_order: 2,
              is_active: true,
              drop_down_options: {
                options: []
              },
              option_text: ""
            },
            {
              type: "phone_number",
              is_mandatory: true,
              title: "Phone Number",
              tooltip: "",
              description: "Enter your phone number",
              sort_order: 3,
              is_active: true,
              drop_down_options: {
                options: []
              },
              option_text: ""
            }
          ]
        },
        "fixed_fields": {
            "pay_button_text": "PAY NOW",
            "amount_field_type": "FIXED",
            "minimum_amount": 0,
            "maximum_amount": 0,
            "minimum_quantity": 0,
            "maximum_quantity": 0
        },
        "available_stock_quantity": 0,
        "is_stock_enabled": false,
        "show_social_media_options": false,
        "send_email_confirmation": false,
        "broker_emails": [],
        "send_broker_email_notification": false,
        "is_dark_mode": false,
        "page_ref": "",
        "page_type": "NORMAL",
        "preview_page_ref": ""
      },
      selectedPaymentMethods: [],
      customToolbar: [
        { 'size': [] },
        'bold', 'italic', 'underline',
        { 'align': [] },
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'color': [] },
        { 'background': [] },
        'link', 'image',
        { 'font': fonts.whitelist }
      ],
      fieldTypes: [
        { value: 'text', text: 'Text' },
        { value: 'name', text: 'Name' },
        { value: 'number', text: 'Number' },
        { value: 'email', text: 'Email' },
        { value: 'url', text: 'Link/URL' },
        { value: 'phone_number', text: 'Phone Number' },
        { value: 'textarea', text: 'Large Textarea' },
        { value: 'dropdown', text: 'Dropdown' },
        { value: 'datepicker', text: 'Date Picker' },
        { value: 'checkbox', text: 'Checkbox' },
      ],
      countries: [],
      currencies: [],
      flagsByCountry: {},
      flagsByCurrency: {}
    };
  },
  validations: {
    broker_notification_email: {
      email
    },
    form: {
      title: {
        required
      },
      description: {
        required
      },
      currency: {
        required
      },
      amount: {
        required
      },
      country_code: {
        required
      },
      merchant_details: {
        email_address: {
          required,
          email
        },
        phone_number: {
          required
        },
      },
      terms_and_conditions: {
        // required
      },
      // "custom_fields": {
      //   "fields": {
      //     $each: {
      //       type: {
      //         required
      //       },
      //       title: {
      //         required
      //       }
      //     }
      //   }
      // },
      // "fixed_fields": {
      //   "pay_button_text": { required },
      //   "amount_field_type": { required },
      //   "minimum_amount": { required },
      //   "maximum_amount": { required },
      //   "minimum_quantity": { required },
      //   "maximum_quantity": { required }
      // },
      // "available_stock_quantity": { required },
    },
    validationGroup: [
    //   'form.title',
    //   'form.title',
    //   'form.description',
    //   'form.currency',
    //   'form.amount',
    //   'form.country_code',
      'form.merchant_details.email_address',
      'form.merchant_details.phone_number',
    ]
  },

  created() {
    let pageRef = this.$route.query.pageRef;
    // this.phoneCountry = this.$store.state.auth.currentUser.country;
    this.form.merchant_details.email_address = this.$store.state.auth.currentUser.email_address;
    this.form.merchant_details.phone_number = this.$store.state.auth.currentUser.phone_number;
    this.countries = Common.countryList();
    this.currencies = Common.currencyList();
    this.flagsByCountry = Common.flagByCountry();
    this.flagsByCurrency = Common.flagByCurrency();

    if(pageRef) {
      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
        pageRef: pageRef,
        submerchantKey: this.$route.query.key,
        isPreviewPage: false
      }).then(() => {
        this.pageDetails = cloneDeep(this.paymentPageDetailStore).content;

        this.pageDetails.success_url = this.form.success_url;
        this.pageDetails.failure_url = this.form.failure_url;
        this.pageDetails.pending_url = this.form.pending_url;
        if(!this.pageDetails.broker_emails){
          this.pageDetails.broker_emails = [];
        }
        if(!this.pageDetails.amount_field_type) {
          this.pageDetails.fixed_fields.amount_field_type = "FIXED";
        }

        this.selectedPaymentMethods = [];
        for(let key in this.pageDetails.chosen_payment_methods) {
          this.pageDetails.chosen_payment_methods[key].map(element => {
              if(element.is_enabled) {
                  this.selectedPaymentMethods.push({
                      payment_channel: element.payment_channel_key,
                      payment_method: element.payment_method_key
                  });
              }
          });
        }

        if(this.$route.query.duplicate == "true") {
          this.form = this.pageDetails;
          this.form.custom_fields.fields = this.form.custom_fields.fields.filter((field) => {
            field.editing = false;
            delete field.key;
            return field.is_active;
          }).sort((a, b) => a.sort_order - b.sort_order);

          this.form.is_active = true;
          this.form.page_ref = "";
          this.form.preview_page_ref = "";
          this.form.page_link = "";
        } else {
          this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
            pageRef: this.pageDetails.preview_page_ref,
            submerchantKey: this.$route.query.key,
            isPreviewPage: true
          }).then(() => {
            this.isEdit = true;
            this.setupEdit();
            if(this.form.terms_and_conditions.length > 0) {
              this.isTermsAndConditions = true;
            }
          });
        }

        if(this.form.terms_and_conditions.length > 0) {
          this.isTermsAndConditions = true;
        }
      })
    } else {
      if(!this.form.country_code) {
        this.form.country_code = this.$store.state.auth.currentUser.country;
      }
      if(!this.form.currency) {
        this.form.currency = Common.currencyByCountry(this.form.country_code);
      }
    }
    
    if(!this.form.merchant_details.brand_primary_color) {
      this.form.merchant_details.brand_primary_color = this.$store.state.auth.currentUser.brand_primary_color ? this.$store.state.auth.currentUser.brand_primary_color : "#FF7628";
    }
    if(!this.form.merchant_details.brand_secondary_color) {
      this.form.merchant_details.brand_secondary_color = this.$store.state.auth.currentUser.brand_secondary_color ? this.$store.state.auth.currentUser.brand_secondary_color : "#f2f2f2";
    }
    this.userName = this.$store.state.auth.currentUser.brand_name;
  },

  computed: {
    paymentPagePreviewDetailStore() {
      return this.$store.state.paymentPages.previewPageDetails;
    },
    paymentPageDetailStore() {
      return this.$store.state.paymentPages.pageDetails;
    },
    paymentMethods() {
      return this.pageDetails ? this.pageDetails.chosen_payment_methods : null;
    }
  },

  watch: {
    'form.merchant_details.phone_number'(value) {
      if(!this.phoneNumberVal) {
        const phoneNumber = parsePhoneNumber(value);
        this.phoneNumberVal = {
          countryCallingCode: phoneNumber.countryCallingCode,
          countryCode: phoneNumber.country,
          formatInternational: phoneNumber.formatInternational(),
          formatNational: phoneNumber.formatNational(),
          formattedNumber: phoneNumber.number,
          isValid: phoneNumber.isValid(),
          nationalNumber: phoneNumber.nationalNumber,
          phoneNumber: phoneNumber.number
        }
      }
    },
    'form.fixed_fields.amount_field_type'(value) {
      if( value == 'CUSTOM_USER_INPUT') {
        this.form.amount = 0;
        this.form.fixed_fields.maximum_quantity = 0;
        this.form.fixed_fields.minimum_quantity = 0;
      } else if( value == 'FIXED_WITH_QUANTITY') {
        this.form.fixed_fields.maximum_amount = 0;
        this.form.fixed_fields.minimum_amount = 0;
      } else {
        this.form.fixed_fields.maximum_quantity = 1;
        this.form.fixed_fields.minimum_quantity = 1;
        this.form.fixed_fields.maximum_amount = 0;
        this.form.fixed_fields.minimum_amount = 0;
      }
    },
    'form.page_ref'() {
      this.isValidCustomUrl = false;
    },
    'isCustomUrl'(value) {
      if(!value) {
        this.customPageRef = "";
      }
    }
  },

  methods: {
    async handlePayInSubmit() {
      if(this.isInProcess) {
        return;
      }
      this.isInProcess = true;
      let formData;
      if(this.isEdit) {
        this.form.page_type = 'PREVIEW';
        formData = await this.prepareFormForSubmit();
        if(!formData) {
          this.isInProcess = false;
          return false;
        }
        formData.page_ref = this.form.page_ref;

        this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, formData)
        .then(() => {
          this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
            pageRef: formData.page_ref,
            submerchantKey: this.$route.query.key,
            isPreviewPage: true
          }).then(async (response) => {
            this.form = cloneDeep(this.paymentPagePreviewDetailStore).content;
            this.form.page_type = 'NORMAL';
            this.form.is_active = this.pageDetails.is_active;

            formData = await this.prepareFormForSubmit();
            formData.custom_fields = response.content.custom_fields;

            // formData.custom_fields = this.previewPageDetails.custom_fields;

            if(!formData) {
              return false;
            }
            formData.available_stock_quantity = this.previewPageDetails.available_stock_quantity;
            formData.page_ref = this.pageDetails.page_ref;
            formData.preview_page_ref = this.pageDetails.preview_page_ref;
            formData.send_broker_email_notification = this.form.send_broker_email_notification;
            formData.broker_emails = this.form.broker_emails;

            this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, formData)
            .then(() => {
              this.$router.push({ path: '/settings/payment-pages-details', query: { pageRef: this.pageDetails.page_ref }})
            })
            .catch((error) => {
              this.$notify({
                type: 'error',
                text: error.message,
                closeOnClick: true,
              });
            })
          });

        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            text: error.message,
            closeOnClick: true,
          });
        })
        .finally(() => {
          this.processing = false;
          this.isInProcess = false;
        });

      } else {

        this.form.page_type = 'NORMAL';
        formData = await this.prepareFormForSubmit();
        if(!formData) {
          this.isInProcess = false;
          return false;
        }
        formData.page_ref = this.customPageRef;
        this.$store.dispatch(`paymentPages/${CREATE_PAYMENT_PAGE_RECORD_IN_STORE}`, formData)
        .then((response) => {
          this.$router.push({ path: '/settings/payment-pages-details', query: { pageRef: response.payment_page_ref }})
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: 'error',
            text: error.message,
            closeOnClick: true,
          });
        })
        .finally(() => this.isInProcess = false);
      }
    },
    async prepareFormForSubmit() {
      this.processing = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError || (!this.phoneNumberVal.isValid && !this.isEdit)) {
        if(this.form.fixed_fields.amount_field_type !== 'CUSTOM_USER_INPUT' && !this.form.amount) {
          this.$notify({
            type: 'error',
            text: 'Amount is required',
            closeOnClick: true,
          });
          return false;
        }
        return false;
      }

      if(!this.phoneNumberVal.isValid) {
        return false;
      }

      for(let index in this.form.custom_fields.fields) {
        if(this.isDuplicateTitle(Number(index))) {
          this.duplicateCustomFields.push(index);
        }
      }

      if(this.duplicateCustomFields.length > 0) {
        return false;
      }

      if(!this.validateAmount()) {
        return false;
      }

      if(this.isCustomUrl && !this.isEdit) {
        await this.checkCustomUrl();

        if(!this.isValidCustomUrl || !this.customPageRef) {
          this.isValidCustomUrl = false;
          this.$notify({
            type: 'error',
            text: this.$t("views.payment_pages.page_ref_invalid_error"),
            closeOnClick: true,
          });
          return false;
        }
      }

      let emptyField = this.form.custom_fields.fields.filter(item => !item.title || !item.type)
      if(emptyField.length > 0) {
        return false;
      }

      this.form.custom_fields.fields = this.form.custom_fields.fields.map((item, index) => {
        item.sort_order = index + 1;
        return item;
      });

      if(this.form.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT') {
        this.form.amount = this.form.fixed_fields.minimum_amount;
      }

      if(!this.isTermsAndConditions) {
        this.form.terms_and_conditions = "";
      }

      this.form.amount = Number.parseFloat(this.form.amount);
      this.form.fixed_fields.minimum_amount = Number.parseFloat(this.form.fixed_fields.minimum_amount);
      this.form.fixed_fields.maximum_amount = Number.parseFloat(this.form.fixed_fields.maximum_amount);
      this.form.fixed_fields.minimum_quantity = Number.parseFloat(this.form.fixed_fields.minimum_quantity);
      this.form.fixed_fields.maximum_quantity = Number.parseFloat(this.form.fixed_fields.maximum_quantity);
      this.form.available_stock_quantity = Number.parseInt(this.form.available_stock_quantity);
      this.form.merchant_details.phone_number = this.phoneNumberVal.formattedNumber;
      this.form.link_description = this.form.title;
      if(!this.form.is_stock_enabled) {
        this.form.available_stock_quantity = 0;
      }

      let formData = null;
      let oldArr = null;
      let normalPage = cloneDeep(this.paymentPageDetailStore).content;
      let previewPage = cloneDeep(this.paymentPagePreviewDetailStore).content;

      if(this.isEdit) {
        if(this.form.page_type == 'PREVIEW') {
          oldArr = previewPage;
        } else {
          oldArr = normalPage;
        }
      }
      if(oldArr) {
        if(oldArr.custom_fields && oldArr.custom_fields.fields && oldArr.custom_fields.fields.length > 0) {
          oldArr.custom_fields.fields = oldArr.custom_fields.fields.sort((a, b) => a.sort_order - b.sort_order)
        }

        formData = this.getArrDiff(oldArr, this.form);
      } else {
        formData = this.form;
      }

      formData.chosen_payment_methods = this.selectedPaymentMethods;

      return formData;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    uploadFile() {
      let fileList = event.target.files;

      if(!fileList) {
        fileList = event.dataTransfer.items;
      }

      if (!fileList.length) return;

      if (!Common.fileValdation(fileList[0])) {
        this.$refs.logoUpload.reset();
        return;
      }

      const formData = new FormData();
      formData.append('file', fileList[0], fileList[0].name);

      this.$store.dispatch(`paymentPages/${UPLOAD_PAGE_IMAGE_IN_STORE}`, formData)
      .then((response) => {
        this.form.merchant_details.logo = response.link;
      })
      .catch((error) => {
        console.log(error);
        this.$notify({
          type: 'error',
          text: error.status_message,
          closeOnClick: true,
        });
      });
    },
    // changeCountry(value) {
    //   this.form.country_code = this.currencies[value];
    // },
    updatePhoneNumber(e) {
      this.phoneNumberVal = e;
      this.form.merchant_details.phone_number = e.nationalNumber ? e.nationalNumber : e.phoneNumber;
    },
    addOption(e, index) {
      if(e.target.value) {
        if(!this.form.custom_fields.fields[index].drop_down_options.options) {
          this.form.custom_fields.fields[index].drop_down_options = {
            options: []
          };
        }
        let duplicate = this.form.custom_fields.fields[index].drop_down_options.options.find(el => el.value == e.target.value)
        if(duplicate) {
          this.$notify({
            type: 'error',
            text: `"${e.target.value}" already exist in options`,
            closeOnClick: true,
          });
        } else {
          this.form.custom_fields.fields[index].drop_down_options.options.push({
            name: e.target.value,
            value: e.target.value
          });
        }
        this.form.custom_fields.fields[index].option_text = '';
      }
    },
    removeOption(key, index) {
      this.form.custom_fields.fields[index].drop_down_options.options.splice(key, 1);
    },
    async addField() {
      await this.form.custom_fields.fields.push({
        type: "text",
        is_mandatory: false,
        key: "",
        title: `New Field ${this.form.custom_fields.fields.length + 1}`,
        tooltip: "",
        description: "",
        sort_order: this.form.custom_fields.fields.length + 1,
        is_active: true,
        drop_down_options: {
          options: []
        },
        option_text: ""
      });
      this.showToast(`custom-toast-${this.form.custom_fields.fields.length - 1}`)
    },
    removeField(index) {
      if(this.form.custom_fields.fields[index].key) {
        this.form.custom_fields.fields[index].is_active = false;
      } else {
        this.form.custom_fields.fields.splice(index,1);
      }
    },
    hideThemeModal() {
      this.$refs['theme-modal'].hide()
    },
    updateDarkMode(isDarkMode) {
      this.form.is_dark_mode = isDarkMode;
    },
    saveTheme() {
      if(!this.isEdit) {
        this.hideThemeModal();
        return;
      }
      let formData = {
        page_ref: this.form.page_ref,
        is_dark_mode: this.form.is_dark_mode,
        merchant_details: {
          brand_primary_color: this.form.merchant_details.brand_primary_color,
          brand_secondary_color: this.form.merchant_details.brand_secondary_color,
          logo: this.form.merchant_details.logo,
        }
      }

      this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, formData)
      .catch((error) => {
        this.$notify({
          type: 'error',
          text: error.message,
          closeOnClick: true,
        });
      })
      this.hideThemeModal();
    },
    checkCustomUrl() {
      if(this.isCustomUrl && !this.isEdit) {
        if(!this.customPageRef) {
          this.isValidCustomUrl = false;
          this.$notify({
            type: 'error',
            text: this.$t("views.payment_pages.page_ref_invalid_error"),
            closeOnClick: true,
          });
          return;
        }
        return this.$store.dispatch(`paymentPages/${CHECK_PAYMENT_PAGE_CUTOM_URL_AVAILABLITY}`, this.customPageRef)
          .then((result) => {
            if(result.status_reason == 'SUCCESS') {
              this.isValidCustomUrl = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.isValidCustomUrl = false;
            this.$notify({
              type: 'error',
              text: this.$t("views.payment_pages.page_ref_invalid_error"),
              closeOnClick: true,
            });
          })
          // error.message
      }
      return true;
    },
    async perviewPage() {
      if(this.isInProcess) {
        return;
      }
      this.isInProcess = true;
      this.form.page_type = 'PREVIEW';

      let formData = await this.prepareFormForSubmit();
      if(!formData) {
        this.isInProcess = false;
        return false;
      }

      if(!this.isEdit && !this.isPreview) {
        formData.page_ref = "";
        this.$store.dispatch(`paymentPages/${CREATE_PAYMENT_PAGE_RECORD_IN_STORE}`, formData)
          .then((result) => {
            if(result.payment_page_link) {
              this.form.page_ref = result.payment_page_ref;
              this.form.page_link = result.payment_page_link;
              this.isPreview = true;
              this.fetchAndPreviewPage();
            }
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              text: error.message,
              closeOnClick: true,
            })
            .finally(() => this.isInProcess = false);
          })
      } else {
        formData.page_ref = this.form.page_ref;

        this.$store.dispatch(`paymentPages/${UPDATE_PAYMENT_PAGE_DETAIL_IN_STORE}`, formData)
          .then((result) => {
            if(result.status_reason == 'SUCCESS') {
              this.fetchAndPreviewPage();
            }
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              text: error.message,
              closeOnClick: true,
            });
          })
          .finally(() => this.isInProcess = false);
      }
    },
    fetchAndPreviewPage() {
      let previewPageRef = this.form.page_ref;
      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_DETAILS}`, {
        pageRef: previewPageRef,
        submerchantKey: this.$route.query.key,
        isPreviewPage: true
      }).then(() => {
        this.setupEdit();
        this.showPreview = true;
        this.isInProcess = false;
      });
    },
    showToast(toastId) {
      this.$bvToast.show(toastId);
      this.toastOpen = true;
    },
    hideToast(toastId) {
      if(toastId.startsWith("custom-toast")) {
        const index = Number(toastId.split('-')[2]);
        const isDuplicate = this.isDuplicateTitle(index);
        if(isDuplicate) {
          this.duplicateCustomFields.push(index);
          return false;
        } else {
          this.duplicateCustomFields = this.duplicateCustomFields.filter(key => key !== index);
        }
      }
      this.$bvToast.hide(toastId);
      this.toastOpen = false;
    },
    isDuplicateTitle(index) {
      const obj = this.form.custom_fields.fields[index];
      const found = this.form.custom_fields.fields.find((field, key) => key !== index && field.title === obj.title && field.is_active && obj.is_active)
      if(found) {
        return true;
      }
      return false;
    },
    showColorPicker(ref) {
      this.$refs[ref].click()
    },
    getArrDiff(oldArr, newArr) {
      let arrDiff = [];
      for(let key in newArr) {
        if(JSON.stringify(newArr[key]) !== JSON.stringify(oldArr[key])) {
          arrDiff[key] = newArr[key];
        }
      }
      for(let key in oldArr) {
        if(!newArr[key] && oldArr[key] && oldArr[key].key) {
          oldArr[key].is_active = false;
          arrDiff[key] = oldArr[key];
        }
      }
      return arrDiff;
    },
    setupEdit() {
      this.previewPageDetails = cloneDeep(this.paymentPagePreviewDetailStore).content;
  
      // let isActiveCustomFieldExist = false;
      // if(this.previewPageDetails.custom_fields.fields) {
      //   isActiveCustomFieldExist = this.previewPageDetails.custom_fields.fields.some((field) => field.is_active)
      // }
      // if(!this.previewPageDetails.custom_fields.fields || !isActiveCustomFieldExist) {
      //   this.previewPageDetails.custom_fields = this.form.custom_fields;
      // }

      this.form = this.previewPageDetails;
      if(this.isEdit) {
        this.form.is_stock_enabled = this.pageDetails.is_stock_enabled;
        this.form.available_stock_quantity = this.pageDetails.available_stock_quantity;
        if(!this.previewPageDetails.broker_emails){
          this.previewPageDetails.broker_emails = [];
        }
      }

      if(this.form.custom_fields && this.form.custom_fields.fields && this.form.custom_fields.fields.length > 0) {
        this.form.custom_fields.fields = this.form.custom_fields.fields.sort((a, b) => a.sort_order - b.sort_order)
      }

      // this.phoneCountry = this.previewPageDetails.country_code;
    },
    validateAmount() {
      if(this.form.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT') {
        if(Number.parseFloat(this.form.fixed_fields.minimum_amount) <= 0) {
          this.$notify({
            type: 'error',
            text: 'Minimum amount value must be greater then 0',
            closeOnClick: true,
          });
          return false;
        }

        if(Number.parseFloat(this.form.fixed_fields.minimum_amount) >= Number.parseFloat(this.form.fixed_fields.maximum_amount)) {
          this.$notify({
            type: 'error',
            text: 'Maximum amount value must be greater then minimum amount',
            closeOnClick: true,
          });
          return false;
        }
      } else if(this.form.fixed_fields.amount_field_type == 'FIXED_WITH_QUANTITY') {

        if(Number.parseInt(this.form.fixed_fields.minimum_quantity) <= 0) {
          this.$notify({
            type: 'error',
            text: 'Minimum quantity value must be greater then 0',
            closeOnClick: true,
          });
          return false;
        }

        if(Number.parseInt(this.form.fixed_fields.minimum_quantity) >= Number.parseInt(this.form.fixed_fields.maximum_quantity)) {
          this.$notify({
            type: 'error',
            text: 'Maximum quantity value must be greater then minimum quantity',
            closeOnClick: true,
          });
          return false;
        }
      }

      if(this.form.fixed_fields.amount_field_type !== 'CUSTOM_USER_INPUT') {
        if(Number.parseFloat(this.form.amount) <= 0) {
          this.$notify({
            type: 'error',
            text: 'Amount must be greater than 0',
            closeOnClick: true,
          });
          return false;
        }
      }
      return true;
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    },
    storePaymentMethods(value) {
      this.selectedPaymentMethods = value;
    },
    addEmailToBrokerEmails() {
      this.$v.broker_notification_email.$touch();
      if (this.$v.broker_notification_email.$invalid) {
        return false;
      }

      if(this.broker_notification_email && !this.form.broker_emails.includes(this.broker_notification_email)) {
        this.form.broker_emails.push(this.broker_notification_email);
      }

      this.$v.broker_notification_email.$reset();
      this.broker_notification_email = "";
    },

    removeEmailFromBrokerList(index) {
      this.form.broker_emails = this.form.broker_emails.filter((email, i) => i !== index);
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },

  directives: {
    'click-outside': {
      bind (el, binding, vnode) {
        const handleOutsideClick = (e) => {
          e.stopPropagation()
          const { handler, exclude, toastId } = binding.value
          let clickedOnExcludedEl = false
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[exclude]
            if(Array.isArray(excludedEl)) {
              clickedOnExcludedEl = !excludedEl[0] || excludedEl[0].contains(e.target)
            } else {
              clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)
            }
          }
          if (!el.contains(e.target) && !clickedOnExcludedEl) {
            if(toastId === "amount-toast") {
              if(vnode.context.validateAmount()) {
                vnode.context[handler](toastId)
              }
            } else {
              vnode.context[handler](toastId)
            }
          }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div style="font-size: 14px;" class="row d-flex justify-content-around">
          <div class="col-12 p-0 mb-4 mt-0">
            <div class="payment-page-header" style="padding-top: 15px;padding-bottom: 15px; color:#fff; background:#161a23; padding-left: 20px;padding-right: 30px; font-size: 16px;display: flex;justify-content: space-between;align-items: center;">
              {{ isEdit ? $t("views.payment_pages.page_header.update") : $t("views.payment_pages.page_header.create") }}
              <div class="float-right">
              <b-btn variant="primary" :disabled="isInProcess" @click="perviewPage" class="border border-light" size="sm">{{ $t("views.payment_pages.button.preview") }}</b-btn>
              <button @click="handlePayInSubmit" :disabled="isInProcess" class="ml-2 btn btn-sm btn-custom">
                {{ isEdit ? $t("views.payment_pages.button.update") : $t("views.payment_pages.button.create") }}
              </button>
              </div>
            </div>
          </div>
          <form id="modal" ref="form" action="#" @submit.prevent="handlePayInSubmit" class="row col-12">
            <div class="col-xl-7 col-sm-12">
              <div class="card border">
                <div class="card-body p-3">
                  <div class="col-md-12 d-flex flex-wrap justify-content-between">
                    <div class="row">
                      <div class="avatar-upload">
                        <div class="avatar-edit">
                          <input type='file' ref="imageUpload" id="imageUpload" accept=".png, .jpg, .jpeg, .svg" @change="uploadFile" />
                          <label for="imageUpload" class="d-flex">
                            <svg style="height: 50%;" class="m-auto" viewBox="0 0 24 24">
                              <path fill="currentColor" d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
                            </svg>
                          </label>
                        </div>
                        <div class="avatar-preview">
                          <div id="imagePreview" v-if="form.merchant_details.logo" :style="`background-image: url(${form.merchant_details.logo});`">
                          </div>
                          <div v-else class="d-flex">
                            <img style="width: 35px;" src="@/assets/images/camera.svg" alt="" class="m-auto">
                          </div>
                        </div>
                      </div>
                      <span class="text-primary my-auto ml-4" style="font-size: 18px;">
                        {{ userName }}
                      </span>
                    </div>
                    <div class="my-auto"> 
                      <b-btn variant="light" size="sm" class="bg-white bordered" v-b-modal.theme-modal style="border-color: #ced4d9 !important;"><i class="mdi mdi-pencil mr-1"></i>{{ $t("views.payment_pages.edit_theme") }}</b-btn>
                    </div>
                  </div>
                  <b-modal id="theme-modal" ref="theme-modal" footer-bg-variant="light" no-close-on-backdrop centered hide-header>
                    <span @click="hideThemeModal"><CloseModalBtn /></span>
                    <!-- <b-btn size="sm" class="float-right rounded-circle px-1 py-0 font-weight-bolder" >X</b-btn> -->
                    <b-form-group
                      :label="$t('common.primary_color')"
                      label-for="brand_primary_color"
                    >
                      <div class="border d-flex rounded w-75" style="border-color: #ced4d9 !important;">
                        <div class="rounded-circle m-1 border border-light d-flex" @click="showColorPicker('brand_primary_color')" style="width: 40px; height: 40px; cursor: pointer;" :style="`background-color: ${form.merchant_details.brand_primary_color}`">
                          <input type="color" ref="brand_primary_color" name="brand_primary_color" v-model="form.merchant_details.brand_primary_color" class="m-auto">
                        </div>
                        <input type="text" v-model="form.merchant_details.brand_primary_color" class="color-input"/>
                        <!-- <span class="my-auto ml-2">{{ form.merchant_details.brand_primary_color }}</span> -->
                      </div>
                    </b-form-group>

                    <b-form-group
                      :label="$t('common.secondary_color')"
                      label-for="brand_secondary_color"
                    >
                      <div class="border d-flex rounded w-75" style="border-color: #ced4d9 !important;">
                        <div class="rounded-circle m-1 border border-light d-flex" @click="showColorPicker('brand_secondary_color')" style="width: 40px; height: 40px; cursor: pointer;" :style="`background-color: ${form.merchant_details.brand_secondary_color}`">
                          <input type="color" ref="brand_secondary_color" name="brand_secondary_color" v-model="form.merchant_details.brand_secondary_color" class="m-auto">
                        </div>
                        <input type="text" v-model="form.merchant_details.brand_secondary_color" class="color-input"/>
                        <!-- <span class="my-auto ml-2">{{ form.merchant_details.brand_secondary_color }}</span> -->
                      </div>
                    </b-form-group>

                    <b-form-group
                      :label="$t('views.payment_pages.light_dark')"
                      label-for="light_dark_mode"
                    >
                      <div class="bg-dark p-2 rounded d-flex justify-content-around" style="width: fit-content;">
                        <b-button :variant="form.is_dark_mode ? 'link' : 'success'" @click="updateDarkMode(false)" :style="!form.is_dark_mode ? 'background: #3EEF09;' : ''" class="mx-auto text-white" size="sm">{{ $t('views.payment_pages.light') }}</b-button>
                        <b-button :variant="form.is_dark_mode ? 'success' : 'link'" @click="updateDarkMode(true)" :style="form.is_dark_mode ? 'background: #3EEF09;' : ''" class="mx-auto text-white" size="sm">{{ $t('views.payment_pages.dark') }}</b-button>
                      </div>
                    </b-form-group>
                    <b-form-group
                      :label="$t('views.payment_pages.your_logo')"
                      label-for="logo"
                      :description="$t('views.payment_pages.file_upload_description')"
                      class="w-75"
                    >
                      <b-form-file
                        :placeholder="$t('views.payment_pages.upload_here')"
                        :drop-placeholder="$t('views.payment_pages.drop_here')"
                        @change="uploadFile"
                        ref="logoUpload"
                      ></b-form-file>
                      <small class="form-text text-muted">{{ $t('views.payment_pages.file_instruction') }}</small>
                    </b-form-group>
                    <div class="d-flex">
                      <b-btn size="sm" variant="primary" class="mx-auto w-50" @click="saveTheme">{{ $t("button.save") }}</b-btn>
                    </div>
                    <template #modal-footer>
                      <span>
                        Changes will reflect on <span style="color: #7E2EF1;">Checkout page, Payment Links, Invoices & Payment pages.</span>
                      </span>
                    </template>
                  </b-modal>
                  <div class="form-group col-md-12 mt-4">
                    <b-form-input
                      id="page_title"
                      name="page_title"
                      type="text"
                      v-model="$v.form.title.$model"
                      :placeholder="$t('views.payment_pages.placeholders.title')"
                      :state="validateState('title')"
                    />
                  </div>
                    <div class="form-group col-md-12 mt-4">
                      <VueEditor
                        id="description"
                        v-model="$v.form.description.$model"
                        :placeholder="$t('views.payment_pages.placeholders.description')"
                        :state="validateState('description')"
                        :class="processing && !$v.form.description.required ? `border border-danger` : ``"
                        :editorToolbar="customToolbar"
                      />
                  </div>
                  <div class="form-group col-md-12 mt-4">
                    <b-form-checkbox
                      id="show_social_media_options"
                      v-model="form.show_social_media_options"
                      name="show_social_media_options"
                      class="text-primary"
                    >
                      {{ $t("views.payment_pages.social_media") }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-12 row">
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label class="text-primary mt-2">{{ $t("views.payment_pages.currency") }}</label>
                        <img
                            class="flag_images"
                            :src="flagsByCurrency[form.currency]"
                            :alt="form.currency"
                        />
                        <b-form-select
                          name="currency"
                          v-model="$v.form.currency.$model"
                          class="form-control custom-select-for-flag"
                          @change="() => selectedPaymentMethods = []"
                          :state="validateState('currency')"
                        >
                          <b-form-select-option value="">{{ $t("views.payment_pages.select_currency") }}</b-form-select-option>
                          <b-form-select-option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</b-form-select-option>
                        </b-form-select>
                        <div v-if="$v.form.currency.$error" class="text-danger font-size-12">{{ "This value is required" }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group position-relative">
                        <label class="text-primary mt-2">{{ $t("views.payment_links.country") }}</label>
                        <img
                            class="flag_images"
                            :src="flagsByCountry[form.country_code]"
                            :alt="form.$resetcountry"
                        />
                        <b-form-select
                          name="country_code"
                          v-model="$v.form.country_code.$model"
                          class="form-control custom-select-for-flag"
                          :state="validateState('country_code')"
                        >
                          <b-form-select-option value="">{{ "Select Country" }}</b-form-select-option>
                          <b-form-select-option v-for="country in countries" :key="country" :value="country">{{ getRegionName(country) }}</b-form-select-option>
                        </b-form-select>
                        <div v-if="$v.form.country_code.$error" class="text-danger font-size-12">{{ "This value is required" }}</div>
                      </div>
                    </div>
                  </div>
                  <payment-method-modal class="col-md-12" :isEdit="isEdit" :selectedPaymentMethods="paymentMethods" @payment-methods="storePaymentMethods" :currency="form.currency" />
                  <div class="form-group col-md-12">
                    <div class="mb-2">
                      <label class="text-primary mt-2">{{ $t("views.payment_pages.contact_us") }}</label>
                      <b-form-input
                        id="email_id"
                        name="email_id"
                        type="email"
                        v-model="form.merchant_details.email_address"
                        :class="$v.validationGroup['form.merchant_details.email_address'].$error ? `is-invalid` : 'is-valid'"
                        :placeholder="$t('views.payment_pages.placeholders.email')"
                      />
                    </div>

                    <VuePhoneNumberInput
                      v-if="isEdit || $store.state.auth.currentUser.phone_number"
                      :value="form.merchant_details.phone_number"
                      id="phone_number"
                      name="phone_number"
                      @update="updatePhoneNumber($event)"
                      :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY', 'KE', 'ZA', 'NG']"
                      class="w-100 rounded"
                      :class="(processing && $v.validationGroup['form.merchant_details.phone_number'].$error) || (processing && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger` : ``"
                      valid-color="#1cbb8c"
                      fetch-country
                    />
                    <VuePhoneNumberInput
                      v-else
                      :value="form.merchant_details.phone_number"
                      id="phone_number"
                      name="phone_number"
                      @update="updatePhoneNumber($event)"
                      :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY', 'KE', 'ZA', 'NG']"
                      class="w-100 rounded"
                      :class="(processing && $v.validationGroup['form.merchant_details.phone_number'].$error) || (processing && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger` : ``"
                      valid-color="#1cbb8c"
                      :default-country-code="form.country_code"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <b-form-checkbox
                      v-model="isTermsAndConditions"
                      class="text-primary my-2"
                      name="add_terms_conditions"
                      id="add_terms_conditions"
                    >
                      Add your Terms And Condition
                    </b-form-checkbox>
                    <VueEditor
                      v-if="isTermsAndConditions"
                      v-model="$v.form.terms_and_conditions.$model"
                      :placeholder="$t('views.payment_pages.placeholders.terms_conditions')"
                      id="terms_condition"
                      name="terms_condition"
                      :state="validateState('terms_and_conditions')"
                      :class="processing && $v.form.terms_and_conditions.$error ? `border border-danger` : ``"
                      :editorToolbar="customToolbar"
                    />
                  </div>
                  <div class="form-group col-md-12 mt-4">
                    <b-form-checkbox
                      id="send_email_confirmation"
                      v-model="form.send_email_confirmation"
                      name="send_email_confirmation"
                      class="text-primary"
                    >
                      {{ $t("views.payment_pages.email_confirmation_send") }}
                    </b-form-checkbox>
                  </div>
          
                  <div class="form-group col-md-12 mt-4">
                    <b-form-checkbox
                      id="multi_send_email_confirmation"
                      v-model="form.send_broker_email_notification"
                      name="multi_send_email_confirmation"
                      class="text-primary"
                    >
                      {{ $t("views.payment_pages.multi_send_email_confirmation") }}
                    </b-form-checkbox>
                  </div>
                  <div v-if="form.send_broker_email_notification" class="form-group">
                    <div class="form-control h-100" :class="{ 'is-invalid': $v.broker_notification_email.$error }">
                      <input
                        v-model="broker_notification_email"
                        type="email"
                        name="broker_notification_email"
                        class="form-control border-0"
                        placeholder="Enter additional emails to receive payment confirmation"
                        @keyup.enter="addEmailToBrokerEmails"
                      />
                      <div class="d-flex justify-content-between">
                        <h5 class="h5 mb-0"><b-badge v-for="(email, index) in this.form.broker_emails" class="mr-1 pl-2 mt-2" :key="index" variant="primary">{{ email }} <b-btn variant="primary" size="sm" @click="removeEmailFromBrokerList(index)"><i class="mdi mdi-close"></i></b-btn></b-badge></h5>

                        <div class="mt-auto">
                          <b-btn variant="primary" @click="addEmailToBrokerEmails()">
                            {{ $t("button.add") }}
                          </b-btn>
                        </div>
                      </div>
                      <div
                        v-if="$v.broker_notification_email.$error"
                        class="invalid-feedback"
                        style="position: absolute;bottom: 15px;"
                      >
                        Email is required
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4" v-if="!isEdit">
                    <div class="d-flex">
                      <b-form-checkbox
                        id="is_custom_url"
                        v-model="isCustomUrl"
                        name="is_custom_url"
                        class="text-primary d-flex"
                      >
                        {{ $t("views.payment_pages.choose_custom_url") }}
                      </b-form-checkbox>
                      <small v-if="isCustomUrl" class="text-info ml-1" style="font-size: small;">(Alpha-numeric with length 10-40)</small>
                    </div>
                    <div class="ml-4 mt-2 d-flex" v-if="isCustomUrl">
                      <div class="d-flex">
                        <p class="my-auto">https://page.portone.cloud/</p>
                      </div>
                      <b-input-group size="sm" class="col-md-6 px-0 mx-0">
                        <b-form-input type="text" name="page_ref" v-model="customPageRef" id="page_ref" class="border border-secondary" :class="isValidCustomUrl == true ? 'border-success' : (isValidCustomUrl == false ? 'border-danger' : 'border-info')" style="border-style: dashed !important; outline: none !important;"></b-form-input>
                        <b-input-group-append>
                          <b-btn variant="primary" size="sm" @click="checkCustomUrl">{{ $t("views.payment_pages.check") }}</b-btn>
                        </b-input-group-append>
                      </b-input-group>
                      <!-- <input type="text" name="page_ref" v-model="customPageRef" id="page_ref" class="border border-secondary mr-4" :class="isValidCustomUrl ? 'border-success' : 'border-danger'" style="border-style: dashed !important; outline: none !important;">
                      <b-btn variant="primary" size="sm" @click="checkCustomUrl">Check</b-btn> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-8 col-md-12">
              <div class="card border">
                <div class="card-body p-3">
                  <label class="text-primary mb-3" style="font-size: 16px;font-weight: 400;">{{ $t("views.payment_pages.payment_details") }}</label>

                  <!-- :class="{ 'border border-danger rounded border-dashed' : (!field.type || !field.title) }" -->
                  <div class="show-border">
                    <div class="row my-1 p-1">
                      <div class="col-sm-4 offset-sm-1 d-flex">
                        <label class="my-auto">
                          {{ $t("common.amount") }}
                        </label>
                      </div>
                      <div class="col-sm-7 d-flex justify-content-between">
                        <div style="width: 85%;">
                          <b-form-input
                            v-if="form.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT'"
                            :placeholder="$t('views.payment_pages.placeholders.amount')"
                            type="number"
                            class="disabled-input"
                            disabled
                          ></b-form-input>
                          <b-form-input
                            v-else
                            type="number"
                            :placeholder="$t('views.payment_pages.placeholders.amount')"
                            v-model="$v.form.amount.$model"
                          ></b-form-input>
                        </div>
                        <div class="d-flex">
                          <b-btn size="sm" variant="white" class="p-0 show-edit my-auto" @click="showToast('amount-toast')">
                            <i class="mdi mdi-pencil"></i>
                          </b-btn>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal-backdrop" v-if="toastOpen"></div>
                  <b-toast
                    id="amount-toast"
                    no-close-button
                    static
                    v-click-outside="{
                      exclude: 'amount-toast',
                      handler: 'hideToast',
                      toastId: 'amount-toast'
                    }"
                    backdrop-filter
                    no-auto-hide
                    style="position: absolute; z-index: 1050;"
                    class="w-100 bg-light"
                  >
                    <div ref="amount-toast">
                      <h6 class="d-flex justify-content-between m-0 p-2 mt-2" @click="showType = !showType" style="cursor: pointer;">
                        {{ $t("views.payment_pages.type") }}
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24" v-show="!showType">
                          <path fill="currentColor" d="M8 9H16L12 16" />
                        </svg>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24" v-show="showType">
                          <path fill="currentColor" d="M8 15H16L12 8" />
                        </svg>
                      </h6>
                      <div v-if="showType">
                        <hr class="my-0 mx-2">
                        <div class="px-3 py-2" @click="form.fixed_fields.amount_field_type = 'FIXED'" :class="{ 'bg-light': form.fixed_fields.amount_field_type == 'FIXED' }">
                          <p class="m-0" style="cursor: pointer;">{{ $t("views.payment_pages.fixed_amount") }}</p>
                          <div v-if="form.fixed_fields.amount_field_type == 'FIXED'" class="mt-1">
                            <b-input-group>
                              <span class="my-auto mr-2 font-weight-light w-50">{{ $t("common.amount") }}</span>
                              <b-form-input
                                size="sm"
                                type="number"
                                :placeholder="$t('views.payment_pages.placeholders.amount')"
                                v-model="$v.form.amount.$model"
                              ></b-form-input>
                            </b-input-group>
                          </div>
                        </div>
                        <div class="px-3 py-2" @click="form.fixed_fields.amount_field_type = 'FIXED_WITH_QUANTITY'" :class="{ 'bg-light': form.fixed_fields.amount_field_type == 'FIXED_WITH_QUANTITY' }">
                          <p class="m-0" style="cursor: pointer;">{{ $t("views.payment_pages.fixed_with_qty") }}</p>
                          <div v-if="form.fixed_fields.amount_field_type == 'FIXED_WITH_QUANTITY'" class="mt-1">
                            <b-input-group class="my-2">
                              <span class="my-auto mr-2 font-weight-light w-25">{{ $t("common.amount") }}</span>
                              <b-form-input
                                size="sm"
                                type="number"
                                :placeholder="$t('views.payment_pages.placeholders.amount')"
                                v-model="$v.form.amount.$model"
                              ></b-form-input>
                            </b-input-group>
                            <div class="row">
                              <b-input-group class="my-2 col-6">
                                <span class="my-auto mr-2 font-weight-light">{{ $t("views.payment_pages.min_qty") }}</span>
                                <b-form-input
                                  size="sm"
                                  type="number"
                                  :placeholder="$t('views.payment_pages.placeholders.min_qty')"
                                  v-model="form.fixed_fields.minimum_quantity"
                                ></b-form-input>
                              </b-input-group>
                              <b-input-group class="my-2 col-6">
                                <span class="my-auto mr-2 font-weight-light">{{ $t("views.payment_pages.max_qty") }}</span>
                                <b-form-input
                                  size="sm"
                                  type="number"
                                  :placeholder="$t('views.payment_pages.placeholders.max_qty')"
                                  v-model="form.fixed_fields.maximum_quantity"
                                ></b-form-input>
                              </b-input-group>
                            </div>
                          </div>
                        </div>
                        <div class="px-3 py-2" @click="form.fixed_fields.amount_field_type = 'CUSTOM_USER_INPUT'" :class="{ 'bg-light': form.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT' }">
                          <p class="m-0" style="cursor: pointer;">{{ $t("views.payment_pages.custom_amount") }}</p>
                          <div v-if="form.fixed_fields.amount_field_type == 'CUSTOM_USER_INPUT'" class="mt-1">
                            <b-input-group class="my-2">
                              <span class="my-auto mr-2 w-50 font-weight-light">{{ $t("views.payment_pages.min_amount") }}</span>
                              <b-form-input
                                size="sm"
                                type="number"
                                :placeholder="$t('views.payment_pages.placeholders.min_amount')"
                                v-model="form.fixed_fields.minimum_amount"
                              ></b-form-input>
                            </b-input-group>
                            <b-input-group class="my-2">
                              <span class="my-auto mr-2 w-50 font-weight-light">{{ $t("views.payment_pages.max_amount") }}</span>
                              <b-form-input
                                size="sm"
                                type="number"
                                :placeholder="$t('views.payment_pages.placeholders.max_amount')"
                                v-model="form.fixed_fields.maximum_amount"
                              ></b-form-input>
                            </b-input-group>
                          </div>
                        </div>
                      </div>
                      <h6 class="m-0 p-2" :class="{ 'bg-light': showUnitAvailablity }">
                        <p style="cursor: pointer;" @click="showUnitAvailablity = !showUnitAvailablity">{{ $t("views.payment_pages.set_units") }}</p>
                        <div v-if="showUnitAvailablity">
                          <b-form-checkbox
                            v-model="form.is_stock_enabled"
                            class="mb-3"
                          >
                            {{ $t("views.payment_pages.limited_stocks") }}
                          </b-form-checkbox>
                          <b-input-group class="px-3 py-2">
                            <span class="my-auto mr-2 w-50 font-weight-light">{{ $t("views.payment_pages.enter_units") }}</span>
                            <b-form-input
                              size="sm"
                              type="number"
                              :placeholder="$t('views.payment_pages.placeholders.units')"
                              :disabled="!form.is_stock_enabled"
                              v-model="form.available_stock_quantity"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                      </h6>
                    </div>
                  </b-toast>

                  <draggable
                    :list="form.custom_fields.fields"
                    class="list-group"
                    handle=".handle"
                  >
                    <div v-for="(field, index) in form.custom_fields.fields" v-show="field.is_active" :key="index">
                      <div v-if="field.is_active" class="py-1">
                        <b-toast
                          :id="`custom-toast-${index}`"
                          no-close-button
                          static
                          v-click-outside="{
                            exclude: `custom-toast-${index}`,
                            handler: 'hideToast',
                            toastId: `custom-toast-${index}`
                          }"
                          backdrop-filter
                          no-auto-hide
                          style="position: absolute; z-index: 1050;"
                          class="w-100 bg-light"
                        >
                          <div :ref="`custom-toast-${index}`" class="p-3">
                            <b-form-group :label="$t('views.payment_pages.type')">
                              <b-form-select
                                v-model="form.custom_fields.fields[index].type"
                                :options="fieldTypes"
                                size="sm"
                              ></b-form-select>
                            </b-form-group>
                            <b-form-group :label="$t('views.payment_pages.dropdown_options')" v-if="field.type == 'dropdown'" description="Press enter to add option">
                              <b-form-input
                                v-model="form.custom_fields.fields[index].option_text"
                                @keyup.enter="addOption($event, index)"
                                type="text"
                                size="sm"
                                class="mb-2"
                              ></b-form-input>
                              <b-badge v-for="(option, key) in form.custom_fields.fields[index].drop_down_options.options" class="font-size-12 mr-1 mb-1" :key="key">
                                {{ option.name }}
                                <span style="cursor: pointer;" @click="removeOption(key, index)"><i class="mdi mdi-close"></i></span>
                              </b-badge>
                            </b-form-group>
                            <b-form-group :label="$t('views.payment_pages.title_custom')">
                              <b-form-input
                                v-model="form.custom_fields.fields[index].title"
                                @keypress.enter="hideToast(`custom-toast-${index}`)"
                                size="sm"
                              ></b-form-input>
                              <p v-if="duplicateCustomFields.includes(index)" class="error font-size-12">Title must be unique.</p>
                            </b-form-group>
                            <b-form-group v-if="field.type === 'checkbox'" label="Description">
                              <b-form-input
                                v-model="form.custom_fields.fields[index].description"
                                @keypress.enter="hideToast(`custom-toast-${index}`)"
                                size="sm"
                              ></b-form-input>
                              <p v-if="duplicateCustomFields.includes(index)" class="error font-size-12">Description must be unique.</p>
                            </b-form-group>
                            <b-form-group :label="$t('views.payment_pages.tooltip')">
                              <b-form-input
                                v-model="form.custom_fields.fields[index].tooltip"
                                size="sm"
                              ></b-form-input>
                            </b-form-group>
                            <b-form-checkbox v-model="form.custom_fields.fields[index].is_mandatory" :value="false" :unchecked-value="true" name="check-button" switch>
                              {{ $t("views.payment_pages.optional") }}
                            </b-form-checkbox>
                          </div>
                        </b-toast>

                        <div class="show-border" :class="{ 'border border-danger rounded border-dashed' : (!field.type || !field.title) }">
                          <span
                            class="border border-primary rounded-circle btn py-0 px-1 bg-white show-edit"
                            :class="{ 'border border-danger rounded' : (!field.type || !field.title) }"
                            style="position: absolute; margin-top: -10px; right: 10px;"
                            @click="removeField(index)"
                          >
                            <i class="mdi mdi-close"></i>
                          </span>
                          <div class="row my-1 p-1">
                            <div class="col-sm-1 d-flex">
                              <h3 class="show-edit my-auto handle" style="cursor: move;"><i class="mdi mdi-drag"></i></h3>
                            </div>
                            <div class="col-sm-4 d-flex">
                              <label class="my-auto d-flex">
                                <span v-if="field.type !== 'checkbox'" style="max-width: 60px;" class="text-break">{{ field.title }}</span>
                                <span v-else style="max-width: 60px;" class="text-break"> 
                                  <input
                                  class="form-control"
                                  v-if="field.type == 'checkbox'"
                                  type="checkbox"
                                  :placeholder="field.description"
                                />
                                </span>
                                <span class="my-auto">
                                  <span v-if="field.is_mandatory">* </span><i class="mdi mdi-information-outline" v-b-tooltip.hover :title="field.tooltip"></i>
                                </span>
                              </label>
                            </div>

                            <div class="col-sm-7 d-flex justify-content-between">
                              <div style="width: 85%;" class="my-auto">
                                <b-form-select
                                  v-if="field.type == 'dropdown'"
                                  disabled
                                >
                                  <b-form-select-option selected>{{ field.description }}</b-form-select-option>
                                  <b-form-select-option v-for="(option, index) in field.drop_down_options.options" :key="index" :value="option.value">{{ option.name }}</b-form-select-option>
                                </b-form-select>
                                <VuePhoneNumberInput
                                  v-else-if="field.type == 'phone_number'"
                                  :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY', 'KE', 'ZA', 'NG']"
                                  class="w-100 rounded custom-phone-number"
                                  color="#000000"
                                  :default-country-code="form.country_code"
                                  disabled
                                />
                                <b-form-textarea
                                  v-else-if="field.type == 'textarea'"
                                  class="w-100"
                                  v-model="form.custom_fields.fields[index].description"
                                ></b-form-textarea>
                                <b-form-input
                                  v-else-if="field.type == 'datepicker'"
                                  type="date"
                                  :placeholder="field.description"
                                  disabled
                                ></b-form-input>
                                <b-form-text
                                  v-else-if="field.type == 'checkbox'"
                                  
                                > {{ field.description }}</b-form-text>
                                
                                <b-form-input
                                  v-else
                                  type="text"
                                  v-model="form.custom_fields.fields[index].description"
                                ></b-form-input>
                              </div>
                              <div class="d-flex">
                                <b-btn @click="showToast(`custom-toast-${index}`)" size="sm" variant="white" class="p-0 show-edit my-auto">
                                  <i class="mdi mdi-pencil"></i>
                                </b-btn>
                              </div>
                            </div>
                            <p v-if="duplicateCustomFields.includes(index)" class="error font-size-12 mx-5 mb-0">Title must be unique.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <div class="d-flex justify-content-center">
                    <b-btn size="sm" variant="light" class="bg-white border border-primary btn btn-light btn-sm mb-4 offset-4" style="width: 45%;" @click="addField">
                      <i class="mdi mdi-plus-circle-outline"></i> {{ $t("views.payment_pages.add_fields") }}
                    </b-btn>
                  </div>
                  <b-popover variant="primary" target="btn-text-edit" triggers="focus" placement="top">
                    <b-form-group :label="$t('views.payment_pages.button_text')">
                      <b-form-input
                        type="text"
                        v-model="form.fixed_fields.pay_button_text"
                      ></b-form-input>
                    </b-form-group>
                  </b-popover>
                  <div class="d-flex justify-content-between">
                    <b-button style="width: 85%" variant="primary">
                      {{ form.fixed_fields.pay_button_text }}
                    </b-button>
                    <b-btn variant="light" id="btn-text-edit">
                      <i class="mdi mdi-pencil"></i>
                    </b-btn>
                  </div>
                  <div class="text-center mt-4">
                    <img src="@/assets/images/card_group.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal v-model="showPreview" id="preview-modal" size="xl" title="Payment Page Preview" hide-footer>
      <iframe :src="`${form.page_link}?isPreview=true`" title="Payment Page Preview" style="height: 80vh" class="w-100"></iframe>
    </b-modal>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;    
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.btn-custom{
  border: 1px solid #252B3A;
  border-radius: 4px;
  background: #fff;
  color: #252B3A;
}
.btn-custom.active{
  border: 1px solid #1cbb8c;
  color: #1cbb8c;
}
.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .vue-circular-progress .percent__int{
  font-size: 16px;
}
::v-deep .nav-tabs > li > a{
  color: #B3AFAF;
}
::v-deep .nav-tabs-custom .nav-item .nav-link.active{
  color:#252B3B;
  background:transparent;
}
::v-deep .nav-tabs-custom .nav-item .nav-link::after{
  background:#252B3B;
}
::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
  margin-top: 25px;
}
::v-deep .page-title-box{
  padding-bottom: 0px;
}
.datepicker-custom{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
  display: inline-block;
}
.disabled-input {
  border-color: #ccc;
  background-color: #f2f2f2;
}
.custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff3d60' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff3d60' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") center right 1.75rem/calc(1.75em + 0.47rem) calc(0.75em + 0.47rem) no-repeat;
}
.custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231cbb8c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(1.75em + 0.47rem) calc(0.75em + 0.47rem) no-repeat;
}
::v-deep .input-tel.is-disabled .input-tel__input, ::v-deep .country-selector__input {
  color: #505d69 !important;
}
.border-dashed {
  border-style: dashed !important;
}
.show-border {
  border: 1px dashed #fff;
  border-radius: 0.25rem;
}
.show-border:hover {
  border: 1px dashed #000;
  border-radius: 0.25rem;
}
.show-border:hover .show-edit {
  display: unset;
}
.show-border:hover .hide-input {
  display: none;
}
.show-edit {
  display: none;
}
.card-body {
  padding: unset;
}
input[type="color"] {
  border: none;
  height: 0;
  width: 0;
  z-index: -1;
}
.color-input {
  border: none;
}
.color-input:focus {
  outline: none;
}
.custom-control {
  z-index: unset;
}
::v-deep #phone_number .resize-observer{
  display: none;
}

::v-deep .flag_images{
  position: absolute;
  width: 20px;
  top: 39px;
  left: 10px;
}

::v-deep .custom-select-for-flag{
  padding-left:35px ;
}
</style>
<style>
.ql-editor.ql-blank:before {
  color: #dfe1e5;
  font-style: normal !important;
  font-size: 0.9rem;
}
.ql-align > .ql-picker-label > svg {
  margin-bottom: 0.25rem !important;
}
.wide-popover {
  width: 100%;
}
.ql-font .ql-picker-label {
  width: 140% !important;
}
.ql-picker-options {
  z-index: 20 !important;
}
.ql-editing {
  margin-left: 15% !important;
}
.toast-body {
  padding: unset;
}
</style>

<style lang="scss" scoped>
.avatar-upload {
  height: 120px;
  position: relative;
  max-width: 125px;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 72px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #FFFFFF;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all .2s ease-in-out;
        &:hover {
            background: #f1f1f1;
            border-color: #d6d6d6;
        }
      }
    }
  }
  .avatar-preview {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    > div {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
::v-deep .btn{
  border-radius: 4px;
}
.form-control{
  font-size: 14px;
}
</style>